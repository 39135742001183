import {
    WEEKDAYS, DAYS,
    TIME_DAYS,
} from '@/mixins/days.js'
import { parseDate } from '@/mixins/convertion.js'
const replicacao = {
    mixins: [parseDate],
    data() {
        return {
            replicar: [],
            replicar_possibilidades: [
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
            ],
        }
    },
    created() {
        this.DAYS = DAYS
        this.WEEKDAYS = WEEKDAYS
        this.TIME_DAYS = TIME_DAYS
    },
    methods: {
        replicavel(dia) {
            return this.replicar_possibilidades[dia].value || false
        },
        texto(ordem, programacao) {
            let prog_unidade = programacao
            if (programacao.programacao_regiao) {
                prog_unidade = programacao.programacao_regiao.programacao_fornecedor.programacao_unidade
            }
            if (programacao.programacao_fornecedor) {
                prog_unidade = programacao.programacao_fornecedor.programacao_unidade
            }
            if (programacao.programacao_unidade) {
                prog_unidade = programacao.programacao_unidade
            }
            let prog = prog_unidade.original
                ? prog_unidade.original
                : prog_unidade
            let dia = new Date(
                prog.data_inicio
            )
            let result = dia.setDate(dia.getDate() + ordem)

            return WEEKDAYS[new Date(result).getDay()]
        },
        editReplicas(programacao, programacoes) {
            this.replicar_possibilidades = [
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
                { "value": false, "pai": {} },
            ]
            let copias
            if (programacao.original != null) {
                copias = programacao.original.copias
                if (
                    copias.findIndex(
                        (element) => element.public_id == programacao.original.public_id
                    ) == -1
                ) {
                    let original = { ...programacao.original }
                    delete original.copias
                    copias.push(original)
                }
                let index = copias.findIndex(
                    (element) => element.public_id == programacao.public_id
                )
                if (index > -1) {
                    copias.splice(index, 1)
                }
            } else {
                copias = programacao.copias
            }


            let prog_unidade = programacao
            if (programacao.programacao_regiao) {
                prog_unidade = programacao.programacao_regiao.programacao_fornecedor.programacao_unidade
            }
            if (programacao.programacao_fornecedor) {
                prog_unidade = programacao.programacao_fornecedor.programacao_unidade
            }
            if (programacao.programacao_unidade) {
                prog_unidade = programacao.programacao_unidade
            }
            let original = prog_unidade.original
                ? prog_unidade.original
                : prog_unidade
            let original_data = new Date(original.data_inicio)
            copias.forEach((copia) => {
                let prog = programacoes.find(
                    (element) => element.public_id == copia.public_id
                )
                if (prog != undefined) {
                    let data = new Date(prog.data_inicio)
                    if (prog.programacao_unidade) {
                        data = new Date(prog.programacao_unidade.data_inicio)
                    }
                    if (prog.programacao_fornecedor) {
                        data = new Date(prog.programacao_fornecedor.programacao_unidade.data_inicio)
                    }
                    if (prog.programacao_regiao) {
                        data = new Date(prog.programacao_regiao.programacao_fornecedor.programacao_unidade.data_inicio)
                    }
                    if (prog.programacao_cliente) {
                        data = new Date(prog.programacao_cliente.programacao_regiao.programacao_fornecedor.programacao_unidade.data_inicio)
                    }
                    if (data > new Date() && !prog.suspendido) {
                        let posicao =
                            (new Date(this.parseDate(data)) -
                                new Date(this.parseDate(original_data))) /
                            (1000 * 60 * 60 * 24)
                        this.replicar_possibilidades[posicao] =
                            { "value": true, "pai": { ...prog } }
                    }
                }
            })
        },
        async editarReplicas(edited,
            editProgramacao,
            addProgramacao
        ) {
            let original = edited.original ? edited.original : edited

            let prog_unidade = edited
            if (edited.programacao_regiao) {
                prog_unidade = edited.programacao_regiao.programacao_fornecedor.programacao_unidade
            }
            if (edited.programacao_fornecedor) {
                prog_unidade = edited.programacao_fornecedor.programacao_unidade
            }
            if (edited.programacao_unidade) {
                prog_unidade = edited.programacao_unidade
            }
            let original_data
            // let pai_original, 
            // if (edited.programacao_unidade) {
            //     pai_original = edited.programacao_unidade.original ? edited.programacao_unidade.original : edited.programacao_unidade
            // }
            // if (edited.programacao_fornecedor) {
            //     pai_original = edited.programacao_fornecedor.original ? edited.programacao_fornecedor.original : edited.programacao_fornecedor
            // }
            // if (edited.programacao_regiao) {
            //     pai_original = edited.programacao_regiao.original ? edited.programacao_regiao.original : edited.programacao_regiao
            // }
            // if (edited.programacao_cliente) {
            //     pai_original = edited.programacao_cliente.original ? edited.programacao_cliente.original : edited.programacao_cliente
            // }
            original_data = new Date(prog_unidade.data_inicio)
            await Promise.all(
                this.replicar.map(async (dia) => {
                    try {
                        // Procura pai
                        let pai = this.replicar_possibilidades[dia].pai
                        let item = { ...edited }
                        if (item.programacao_unidade) {
                            item.programacao_unidade = pai
                        }
                        if (item.programacao_fornecedor) {
                            item.programacao_fornecedor = pai
                        }
                        if (item.programacao_regiao) {
                            item.programacao_regiao = pai
                        }
                        if (item.programacao_cliente) {
                            item.programacao_cliente = pai
                        }
                        if (pai) {
                            // -- 
                            // A váriavel "prog" vai receber alguma programação fornecedor onde
                            // o public_id seja de uma réplica sua ou do seu original
                            // ou seja o próprio original
                            // cujo o dia da semana seja igual ao escolhido para replicar(SEGUNDA, TERÇA, etc)
                            let copias = original.copias
                            if (
                                copias.findIndex(
                                    (element) =>
                                        element.public_id == original.public_id
                                ) == -1
                            ) {
                                let _original = { ...original }
                                delete _original.copias
                                copias.push(_original)
                            }
                            let prog = copias.find((element) => {
                                let data = new Date(element.data_inicio)
                                let posicao =
                                    (new Date(this.parseDate(data)) -
                                        new Date(this.parseDate(original_data))) /
                                    (1000 * 60 * 60 * 24)
                                return (
                                    // Verifica se o que está sendo procurado é uma copia. Redundante por conta do find que está sendo utilizado no '.copias'
                                    // ((edited.original
                                    //     ? edited.original.copias
                                    //     : edited.copias
                                    // ).findIndex((el) => el == element.public_id) != -1 ||
                                    //     (edited.original
                                    //         ? edited.original.public_id == element.public_id
                                    //         : 0)) &&
                                    posicao == dia
                                )
                            })
                            if (prog) {
                                item.public_id = prog.public_id
                                item.original = original
                                item.id = prog.id
                                await editProgramacao(item)
                            } else {
                                item.original = original
                                item.public_id = null
                                item.id = null
                                await addProgramacao(item)
                            }
                        }
                    } catch (error) {
                        if (
                            error.response &&
                            (error.response.status == 403 || error.response.status == 404)
                        ) {
                            this.errorMessage(
                                'Empresa atual não pode cadastrar/editar programações.'
                            )
                        } else {
                            this.errorMessage(error.response ? error.response.data : error)
                        }
                    }
                })
            )
        },

        async cadastrarReplicas(original, addProgramacao) {
            // let { original } = params
            await Promise.all(
                this.replicar.map(async (element) => {
                    try {
                        let item = { ...original }
                        item.public_id = null
                        // Procura pai
                        let pai = this.replicar_possibilidades[element].pai
                        if (item.programacao_unidade) {
                            item.programacao_unidade = pai
                        }
                        if (item.programacao_fornecedor) {
                            item.programacao_fornecedor = pai
                        }
                        if (item.programacao_regiao) {
                            item.programacao_regiao = pai
                        }
                        if (item.programacao_cliente) {
                            item.programacao_cliente = pai
                        }
                        if (pai) {
                            // --
                            item.original = original
                            await addProgramacao(item)
                        }
                    } catch (error) {
                        if (
                            error.response &&
                            (error.response.status == 403 || error.response.status == 404)
                        ) {
                            this.errorMessage(
                                'Empresa atual não pode cadastrar/editar programações.'
                            )
                        } else {
                            this.errorMessage(error.response ? error.response.data : error)
                        }
                    }
                })
            )
        },
    },
}
export { replicacao }