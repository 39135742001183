<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-sheet tile height="54" class="d-flex">
      <v-btn outlined class="ma-2" color="grey darken-2" @click="setToday">
        Hoje
      </v-btn>
      <v-autocomplete
        v-model="type"
        :items="types"
        item-text="text"
        item-value="value"
        dense
        outlined
        hide-details
        class="ma-2"
        label="Tipos"
      ></v-autocomplete>
      <v-autocomplete
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="Dias da Semana"
        class="ma-2"
      ></v-autocomplete>
      <v-btn
        outlined
        class="ma-2"
        color="grey darken-2"
        @click="atualizarListaProgramacoesUnidade()"
        >Atualizar
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @change="getEvents"
      >
        <template v-slot:event="{ event, timed, eventSummary }">
          <div class="v-event-draggable" v-html="eventSummary()"></div>
          <div class="ma-2">
            <b>Quantidade de cotas a distribuir para regiões:</b>
            <table
              style="width: 90%; background: white; color: black; margin: 10px"
              border="1px"
            >
              <thead>
                <tr>
                  <th style="padding: 1px">Produto</th>
                  <th style="padding: 1px">Terminal</th>
                  <th style="padding: 1px">Designado</th>
                  <th style="padding: 1px">distribuidos</th>
                  <th style="padding: 1px">Restante</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="event.item.qt_caminhoes > 0">
                  <td style="padding: 1px">
                    {{ event.item.produto.descricao }}
                  </td>
                  <td style="padding: 1px">
                    {{ event.item.terminal.trade_name }}
                  </td>
                  <td style="padding: 1px">
                    {{
                      event.item.qt_caminhoes ? event.item.qt_caminhoes : '0'
                    }}
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_distribuida_caminhoes(event.item) }}
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_restante_caminhoes_a_distribuir(event.item) }}
                  </td>
                </tr>
                <tr v-if="event.item.qt_peso > 0">
                  <td style="padding: 1px">
                    {{ event.item.produto.descricao }}
                  </td>
                  <td style="padding: 1px">
                    {{ event.item.terminal.trade_name }}
                  </td>
                  <td style="padding: 1px">
                    {{ event.item.qt_peso ? event.item.qt_peso : '0' }}
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_distribuida_peso(event.item) }}
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_restante_peso_a_distribuir(event.item) }}
                  </td>
                </tr>
              </tbody>
            </table>

            <b>Cotas distribuidas pelas regiões:</b>
            <table
              style="width: 90%; background: white; color: black; margin: 10px"
              border="1px"
            >
              <thead>
                <tr>
                  <th style="padding: 1px">Região</th>
                  <th style="padding: 1px">Designado</th>
                  <th style="padding: 1px">Distribuidas</th>
                  <th style="padding: 1px">Restante</th>
                  <th style="padding: 1px">Performace</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in event.item.programacoes_fornecedor"
                  :key="index"
                >
                  <td style="padding: 1px">{{ item.regiao.sigla }}</td>
                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ item.qt_caminhoes }}
                  </td>
                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ item.qt_peso }}
                  </td>

                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ item.quantidade_distribuida }}
                  </td>
                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ item.qt_caminhoes - item.quantidade_distribuida }}
                  </td>
                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{
                      (
                        (item.quantidade_distribuida * 100) /
                        item.qt_caminhoes
                      ).toFixed(2) + ' %'
                    }}
                  </td>

                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ item.quantidade_distribuida }}
                  </td>
                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ item.qt_peso - item.quantidade_distribuida }}
                  </td>
                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{
                      (
                        (item.quantidade_distribuida * 100) /
                        item.qt_peso
                      ).toFixed(2) + ' %'
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <b>Cotas atendidas pelas regiões:</b>
            <table
              style="width: 90%; background: white; color: black; margin: 10px"
              border="1px"
            >
              <thead>
                <tr>
                  <th style="padding: 1px">Região</th>
                  <th style="padding: 1px">Designado</th>
                  <th style="padding: 1px">Atendidos</th>
                  <th style="padding: 1px">Restante</th>
                  <th style="padding: 1px">Performace</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in event.item.programacoes_fornecedor"
                  :key="index"
                >
                  <td style="padding: 1px">{{ item.regiao.sigla }}</td>

                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ item.qt_caminhoes }}
                  </td>
                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ quantidade_atendida(item) }}
                  </td>
                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ quantidade_restante_caminhoes(item) }}
                  </td>
                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ performace_caminhoes(item) }}
                  </td>

                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ item.qt_peso }} TON
                  </td>
                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ quantidade_agendada_peso(item) }} TON
                  </td>
                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ quantidade_restante_peso(item) }} TON
                  </td>
                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ performace_caminhoes_peso(item) }} TON
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
    <v-dialog
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-container>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form>
              <v-card-title>
                <span class="headline">Programação Fornecedor</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="3">
                      <v-autocomplete
                        readonly
                        v-model="programacaoUnidade.proprietario"
                        label="Gate/Unidade"
                        :items="[programacaoUnidade.proprietario]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-boom-gate"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <v-autocomplete
                        readonly
                        v-model="programacaoUnidade.fornecedor"
                        label="Fornecedor"
                        :items="[programacaoUnidade.fornecedor]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-equalizer"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete
                        readonly
                        v-model="programacaoUnidade.tipo_operacao"
                        label="Tipo de Operação"
                        :items="[programacaoUnidade.tipo_operacao]"
                        item-text="descricao"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-clipboard-flow-outline"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete
                        readonly
                        v-model="programacaoUnidade.terminal"
                        label="Terminal"
                        :items="[programacaoUnidade.terminal]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-alpha-t-box-outline"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-divider class="my-5"></v-divider>
                  <v-row>
                    <v-col class="pt-0" cols="12" sm="4">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="programacaoUnidade.tipo_controle"
                        label="Tipo de controle"
                        :items="
                          programacaoUnidade.tipo_controle
                            ? [programacaoUnidade.tipo_controle]
                            : []
                        "
                        item-text="descricao"
                        item
                        prepend-icon="mdi-clipboard-alert-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="4">
                      <v-autocomplete
                        readonly
                        v-model="programacaoUnidade.modalidades"
                        label="Tipos de Veículo"
                        :items="programacaoUnidade.modalidades"
                        item-text="descricao"
                        item-value="id"
                        multiple
                        chips
                        prepend-icon="mdi-truck-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="4">
                      <v-autocomplete
                        readonly
                        v-model="programacaoUnidade.carrocerias"
                        label="Tipos de Carrocerias"
                        :items="programacaoUnidade.carrocerias"
                        item-text="descricao"
                        item-value="id"
                        multiple
                        chips
                        prepend-icon="mdi-truck-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="12">
                      <v-text-field
                        readonly
                        v-model="programacaoUnidade.observacao"
                        :value="programacaoUnidade.observacao"
                        label="Observação"
                        prepend-icon="mdi-note-text-outline"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" lg="3">
                      <v-autocomplete
                        readonly
                        v-model="programacaoUnidade.produto"
                        label="Produto"
                        :items="
                          programacaoUnidade.produto
                            ? [programacaoUnidade.produto]
                            : []
                        "
                        item-text="descricao"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-alpha-p-box-outline"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                      <v-autocomplete
                        readonly
                        v-model="programacaoUnidade.sub_produto"
                        :items="
                          programacaoUnidade.sub_produto
                            ? [programacaoUnidade.sub_produto]
                            : []
                        "
                        item-text="descricao"
                        label="Sub Produto"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-alpha-p-circle-outline"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                      <div class="mt-5 d-flex justify-space-around">
                        <label>Data inicial:</label>
                        <input
                          type="datetime-local"
                          readonly
                          name="datetime"
                          :value="
                            date2iso(new Date(programacaoUnidade.data_inicio))
                          "
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                      <div class="mt-5 d-flex justify-space-around">
                        <label>Data final:</label>
                        <input
                          type="datetime-local"
                          readonly
                          name="datetime"
                          :value="
                            date2iso(new Date(programacaoUnidade.data_fim))
                          "
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-container>
                  <v-card-title v-if="canCreate">
                    <span class="headline">Distribuir nova cota</span>
                  </v-card-title>
                  <v-row v-if="canCreate">
                    <v-col cols="11">
                      <v-row>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <v-autocomplete
                            class="mt-3"
                            v-model="editedItem.regiao"
                            label="Região"
                            :items="regioes"
                            item-text="sigla"
                            item-value="public_id"
                            hint="Selecione a região."
                            persistent-hint
                            prepend-icon="mdi-home-city"
                            required
                            return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <v-autocomplete
                            v-model="editedItem.tipos_frete"
                            :items="tiposFrete"
                            item-text="descricao"
                            item-value="id"
                            label="Tipo de Frete"
                            prepend-icon="mdi-truck"
                            chips
                            clearable
                            deletable-chips
                            multiple
                            hint="Selecione o tipo de frete."
                            persistent-hint
                            required
                            return-object
                            v-on:input="limiter"
                            :counter="limit"
                          />
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <v-text-field
                            class="mt-3"
                            type="number"
                            v-model="editedItem.qt_peso"
                            label="Toneladas"
                            :max="qt_peso(programacaoUnidade)"
                            :min="0"
                            :disabled="qt_peso(programacaoUnidade) == 0"
                            prepend-icon="mdi-weight"
                            hint="Indique quantas Toneladas será programada."
                            persistent-hint
                            :rules="[
                              () =>
                                editedItem.qt_peso <=
                                  qt_peso(programacaoUnidade) ||
                                'Quantidade de toneladas é maior que a quantidade permitida.',
                            ]"
                          >
                            <template v-slot:label>
                              Restante: {{ qt_peso(programacaoUnidade) }}
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <v-text-field
                            class="mt-3"
                            type="number"
                            v-model="editedItem.qt_caminhoes"
                            label="Caminhões"
                            :max="qt_caminhoes(programacaoUnidade)"
                            :min="0"
                            :disabled="qt_caminhoes(programacaoUnidade) == 0"
                            prepend-icon="mdi-truck-check-outline"
                            hint="Indique quantos Caminhões será programado."
                            persistent-hint
                            :rules="[
                              () =>
                                editedItem.qt_caminhoes <=
                                  qt_caminhoes(programacaoUnidade) ||
                                'Quantidade de caminhões é maior que a quantidade permitida.',
                            ]"
                          >
                            <template v-slot:label>
                              Restante:
                              {{ qt_caminhoes(programacaoUnidade) }}
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        block
                        color="info"
                        class="text-capitalize"
                        @click="addProgramacao"
                        :disabled="
                          (qt_peso(programacaoUnidade) <= 0 &&
                            qt_caminhoes(programacaoUnidade) <= 0) ||
                          editedItem.qt_peso > qt_peso(programacaoUnidade) ||
                          editedItem.qt_caminhoes >
                            qt_caminhoes(programacaoUnidade)
                        "
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <br />
                  <div
                    v-if="programacaoUnidade.programacoes_fornecedor.length > 0"
                  >
                    <v-divider></v-divider>
                    <v-card-title>
                      <span class="headline">Editar distribuição de cotas</span>
                    </v-card-title>
                    <v-row
                      v-for="(
                        programacao, i
                      ) in programacaoUnidade.programacoes_fornecedor"
                      v-bind:key="i"
                    >
                      <v-col cols="11">
                        <v-row>
                          <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                            <validation-provider
                              v-slot="{ errors }"
                              name="regiao"
                              rules="required"
                            >
                              <v-autocomplete
                                class="mt-3"
                                v-model="programacao.regiao"
                                label="Região"
                                :items="regioes"
                                :error-messages="errors"
                                item-text="sigla"
                                item-value="public_id"
                                hint="Selecione a região."
                                persistent-hint
                                prepend-icon="mdi-home-city"
                                required
                                return-object
                              ></v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                            <validation-provider
                              v-slot="{ errors }"
                              name="tipos_frete"
                              rules="required"
                            >
                              <v-autocomplete
                                v-model="programacao.tipos_frete"
                                :items="tiposFrete"
                                :error-messages="errors"
                                item-text="descricao"
                                item-value="id"
                                label="Tipo de Frete"
                                prepend-icon="mdi-truck"
                                chips
                                clearable
                                deletable-chips
                                multiple
                                hint="Selecione o tipo de frete."
                                persistent-hint
                                required
                                return-object
                                v-on:input="limiter"
                                :counter="limit"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                            <validation-provider
                              v-slot="{ errors }"
                              name="qt_peso"
                              rules="required"
                            >
                              <v-text-field
                                class="mt-3"
                                type="number"
                                v-model="programacao.qt_peso"
                                label="Toneladas"
                                :max="
                                  programacao.qt_peso * 1 +
                                  qt_peso(programacaoUnidade) +
                                  100
                                "
                                :min="0"
                                :disabled="programacaoUnidade.qt_peso == 0"
                                prepend-icon="mdi-weight"
                                :error-messages="errors"
                                hint="Indique quantas Toneladas será programada."
                                persistent-hint
                                :rules="[
                                  () =>
                                    0 <= qt_peso(programacaoUnidade) + 100 ||
                                    'Quantidade de toneladas é maior que a quantidade permitida.',
                                ]"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                            <validation-provider
                              v-slot="{ errors }"
                              name="qt_caminhoes"
                              rules="required"
                            >
                              <v-text-field
                                class="mt-3"
                                type="number"
                                v-model="programacao.qt_caminhoes"
                                label="Caminhões"
                                :max="
                                  programacao.qt_caminhoes * 1 +
                                  qt_caminhoes(programacaoUnidade)
                                "
                                :min="0"
                                :disabled="programacaoUnidade.qt_caminhoes == 0"
                                prepend-icon="mdi-truck-check-outline"
                                :error-messages="errors"
                                hint="Indique quantos Caminhões será programado."
                                persistent-hint
                                :rules="[
                                  () =>
                                    0 <= qt_caminhoes(programacaoUnidade) * 1 ||
                                    'Quantidade de caminhões é maior que a quantidade permitida.',
                                ]"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          block
                          color="error"
                          class="text-capitalize"
                          @click="() => removeProgramacao(i)"
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <v-row v-if="canCreate">
                    <v-col cols="12" md="12" lg="6">
                      <label>Replicar:</label>
                      <br />
                      <v-btn-toggle v-model="replicar" multiple>
                        <v-btn :disabled="!replicavel(DAYS.PRIMEIRO)">
                          {{ texto(DAYS.PRIMEIRO, programacaoUnidade) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.SEGUNDO)">
                          {{ texto(DAYS.SEGUNDO, programacaoUnidade) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.TERCEIRO)">
                          {{ texto(DAYS.TERCEIRO, programacaoUnidade) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.QUARTO)">
                          {{ texto(DAYS.QUARTO, programacaoUnidade) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.QUINTO)">
                          {{ texto(DAYS.QUINTO, programacaoUnidade) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.SEXTO)">
                          {{ texto(DAYS.SEXTO, programacaoUnidade) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.SETIMO)">
                          {{ texto(DAYS.SETIMO, programacaoUnidade) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="12" class="d-flex justify-space-between">
                    <div>
                      <router-link
                        v-if="
                          !(
                            qt_peso(programacaoUnidade) == 0 &&
                            qt_caminhoes(programacaoUnidade) == 0
                          ) &&
                          editavel &&
                          notSuspended &&
                          !invalid
                        "
                        :to="{
                          name: 'NovoAgendamento',
                          params: {
                            ...paramsToAgendamento(programacaoUnidade),
                          },
                        }"
                      >
                        <v-btn color="primary" small>Novo Agendamento</v-btn>
                      </router-link>
                      <v-btn
                        small
                        class="mx-3"
                        @click="detalhesDistribuicao(programacaoUnidade)"
                      >
                        Agendamentos
                      </v-btn>
                    </div>
                    <v-btn
                      v-if="canUpdate"
                      small
                      :disabled="invalid || !editavel || !notSuspended"
                      @click="saveAll"
                    >
                      Salvar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-container>
      </v-card>
      <v-overlay :value="carregaDetalhes">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="detalhar">
      <detalhes-programacao
        :detalhes="detalhes"
        :headers="headersDetalhes"
        @close="() => (detalhar = false)"
      />
    </v-dialog>
  </div>
</template>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  paramsToAgendamento,
  contabilizadores,
  pesquisa,
} from '@/mixins/cotas.js'
import { replicacao } from '@/mixins/replicacao.js'
import { DAYS, date2iso } from '@/mixins/days.js'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import DetalhesProgramacao from '@/components/patio/Programacao/Detalhes/DetalhesProgramacao'

// import PatioApi from '@/services/patio'
export default {
  name: 'CalendarioProgramacaoFornecedor',
  props: ['params'],
  mixins: [paramsToAgendamento, contabilizadores, pesquisa, replicacao],
  components: {
    DetalhesProgramacao,
  },
  data() {
    return {
      detalhes: [],
      detalhar: false,
      headersDetalhes: [
        { text: 'Status', value: 'status' },
        { text: 'Placa', value: 'veiculo_placa' },
        { text: 'Motorista', value: 'motorista_name' },
        { text: 'Cliente', value: 'cliente_name' },
        { text: 'Região', value: 'regiao_sigla' },
        { text: 'Data do Agendamento', value: 'agendado_em' },
      ],
      carregaDetalhes: false,
      type: 'week',
      loading: false,
      types: [
        { text: 'Mês', value: 'month' },
        { text: 'Semana', value: 'week' },
        { text: 'Dia', value: 'day' },
      ],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [
        DAYS.DOMINGO,
        DAYS.SEGUNDA,
        DAYS.TERCA,
        DAYS.QUARTA,
        DAYS.QUINTA,
        DAYS.SEXTA,
        DAYS.SABADO,
      ],
      weekdays: [
        {
          text: 'Domingo - Sábado',
          value: [
            DAYS.DOMINGO,
            DAYS.SEGUNDA,
            DAYS.TERCA,
            DAYS.QUARTA,
            DAYS.QUINTA,
            DAYS.SEXTA,
            DAYS.SABADO,
          ],
        },
        {
          text: 'Segunda - Sexta',
          value: [
            DAYS.SEGUNDA,
            DAYS.TERCA,
            DAYS.QUARTA,
            DAYS.QUINTA,
            DAYS.SEXTA,
          ],
        },
      ],
      value: '',
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
      selectFornecedor: [],
      selectProduto: [],
      selectSubProduto: [],
      selectTerminal: [],
      tab: null,
      limit: 1,
      editedIndex: -1,
      defaultItem: {
        public_id: '',
        regiao: null,
        tipos_frete: null,
        qt_peso: 0,
        qt_caminhoes: 0,
        copias: null,
      },
      editedItem: {
        public_id: '',
        regiao: null,
        tipos_frete: null,
        qt_peso: 0,
        qt_caminhoes: 0,
        copias: null,
      },
    }
  },
  computed: {
    ...mapState('patio', ['regioes', 'tiposFrete']),
    ...mapState('auth', ['empresaAtual']),
    ...mapState('programacao', ['programacaoUnidade', 'programacoesUnidade']),

    editavel() {
      if (this.programacaoUnidade) {
        if (new Date(this.programacaoUnidade.data_fim) > new Date()) {
          return true
        }
      }
      return false
    },
    suspendivel() {
      if (this.editedIndex >= 0) {
        if (new Date(this.editedItem.data_fim) > new Date()) {
          return true
        }
      }
      return false
    },
    notSuspended() {
      if (this.programacaoUnidade) {
        return this.programacaoUnidade.suspendido == false
      }
      return true
    },
    allowedToAdd() {
      return this.empresaAtual.gate
    },
    formTitle() {
      return this.editedIndex === -1
        ? 'Distribuir nova cota'
        : 'Editar cota distribuida'
    },
    permission() {
      return Sequences.CotasFornecedor.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    this.date2iso = date2iso
    // this.loading = true
    // await this.atualizarListaProgramacoesUnidade()
    // this.loading = false
  },
  watch: {},
  methods: {
    ...mapActions('patio', [
      'getRegioes',
      'getTiposFrete',
      'deleteProgramacaoFornecedor',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapMutations('programacao', ['SET_PROGRAMACAO_UNIDADE']),
    ...mapActions('programacao', [
      'addProgramacaoFornecedor',
      'editProgramacaoFornecedor',
      'getProgramacaoUnidade',
      'getProgramacoesUnidade',
      'getProgramacaoDetalhe',
    ]),
    async detalhesDistribuicao(item) {
      try {
        this.carregaDetalhes = true
        const data = await this.getProgramacaoDetalhe({
          type: 'Unidade',
          public_id: item.public_id,
        })
        this.detalhes = data
        this.detalhar = true
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregaDetalhes = false
      }
    },

    limiter(e) {
      if (e.length > this.limit) {
        console.log('Não permitido adicionar', e.pop()?.descricao)
      }
    },
    addProgramacao() {
      this.programacaoUnidade.programacoes_fornecedor.push({
        ...this.editedItem,
      })
      Object.assign(this.editedItem, this.defaultItem)
    },
    async removeProgramacao(index) {
      let programacao = this.programacaoUnidade.programacoes_fornecedor[index]
      if (programacao.public_id != '') {
        try {
          await this.deleteProgramacaoFornecedor(programacao)
          this.programacaoUnidade.programacoes_fornecedor.splice(index, 1)
        } catch (error) {
          if (error.response) {
            if (error.response.data) {
              const data = error.response.data
              if (data.error_quantidade) {
                this.errorMessage(data.error_quantidade)
                if (data.novo_qt_peso) {
                  programacao.qt_peso = data.novo_qt_peso
                }
                if (data.novo_qt_caminhoes) {
                  programacao.qt_caminhoes = data.novo_qt_caminhoes
                }
                return
              }
            }
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
      this.programacaoUnidade.programacoes_fornecedor.splice(index, 1)
    },
    async saveAll() {
      let errors = []
      await Promise.all(
        this.programacaoUnidade.programacoes_fornecedor.map(
          async (programacao) => {
            try {
              let prog_fornecedor = await this.save(
                {
                  ...programacao,
                  programacao_unidade: this.programacaoUnidade,
                },
                true
              )
              Object.assign(programacao, prog_fornecedor)
            } catch (error) {
              errors.push({ programacao: programacao, error: error })
            }
          }
        )
      )
      const map_errors = errors.map((item) => {
        const ret = {}
        let error = null
        if (
          item.error.response &&
          (item.error.response.status == 403 ||
            item.error.response.status == 404)
        ) {
          error = 'Empresa atual não pode cadastrar/editar programações.'
        } else {
          if (item.error.response) {
            error = item.error.response.data
          } else if (item.error.message) {
            error = item.error.message
          } else {
            error = item.error
          }
          if (item.error.response && 'qt_caminhoes' in error) {
            error = error.qt_caminhoes
          }
          if (item.error.response && 'qt_peso' in error) {
            error = error.qt_peso
          }
        }
        const key = `${item.programacao.regiao.sigla} - ${item.programacao.tipos_frete[0].descricao}`
        ret[key] = error
        return ret
      })
      if (map_errors.length > 0) {
        this.errorMessage(map_errors)
      }
    },
    async save(programacao, skip_errors = false) {
      try {
        let prog_fornecedor
        if (programacao.public_id != '') {
          prog_fornecedor = await this.editProgramacaoFornecedor(programacao)
          if (this.replicar.length > 0) {
            await this.editarReplicas(
              prog_fornecedor,
              this.editProgramacaoFornecedor,
              this.addProgramacaoFornecedor
            )
          }
        } else {
          prog_fornecedor = await this.addProgramacaoFornecedor(programacao)
          if (this.replicar.length > 0) {
            await this.cadastrarReplicas(
              prog_fornecedor,
              this.addProgramacaoFornecedor
            )
          }
        }
        this.close()
        return prog_fornecedor
      } catch (error) {
        if (skip_errors) {
          return Promise.reject(error)
        }
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage(
            'Empresa atual não pode cadastrar/editar programações.'
          )
        } else {
          console.log(error)
          this.errorMessage(error.response ? error.response.data : error)
        }
      }
    },

    async editItem(item) {
      this.replicar = []
      this.editReplicas(item, this.programacoesUnidade)
      this.SET_PROGRAMACAO_UNIDADE(item)
      await this.getRegioes({ public_id: item.fornecedor.fornecedor_id })
      await this.getTiposFrete({ public_id: item.fornecedor.fornecedor_id })
    },
    close() {
      this.selectedOpen = false
    },
    async atualizarListaProgramacoesUnidade(params = null) {
      try {
        if (params == null) {
          params = this.params
        }
        let filtros = this.pesquisa(params)
        if (!filtros.data_inicio) {
          filtros.data_inicio = new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).setDate(
              new Date().getDate() - new Date().getDay()
            )
          )
            .toISOString()
            .split('T')[0]
        }
        await this.getProgramacoesUnidade(filtros)
        this.events = []
        this.getEvents()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso as programações.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    createName(programacao) {
      let name = ''
      name += programacao.fornecedor
        ? programacao.fornecedor.trade_name
          ? programacao.fornecedor.trade_name
          : programacao.fornecedor.fornecedor_trade_name
        : ''
      if (programacao.produto) {
        name += ' - ' + programacao.produto.descricao
      }
      if (programacao.sub_produto) {
        name += ' - ' + programacao.sub_produto.descricao
      }
      if (programacao.qt_caminhoes) {
        name += ' - ' + programacao.qt_caminhoes + ' Cam.'
      }
      if (programacao.qt_peso) {
        name += ' - ' + programacao.qt_peso + ' Ton.'
      }
      return name
    },
    getEvents() {
      this.programacoesUnidade.forEach((element) => {
        let i = this.events.findIndex(
          (event) => event.item.public_id == element.public_id
        )
        let start = new Date(element.data_inicio)
        let end = new Date(element.data_fim)
        if (i == -1) {
          this.events.push({
            item: element,
            name: this.createName(element),
            color:
              element.suspendido == 1
                ? 'red'
                : element.tipo_operacao
                ? element.tipo_operacao.id == 1
                  ? this.colors[4]
                  : this.colors[0]
                : this.colors[0],
            start: start.getTime(),
            end: end.getTime(),
            timed: true,
          })
        } else {
          this.events[i] = {
            item: element,
            name: this.createName(element),
            color:
              element.suspendido == 1
                ? 'red'
                : element.tipo_operacao
                ? element.tipo_operacao.id == 1
                  ? this.colors[4]
                  : this.colors[0]
                : this.colors[0],
            start: start.getTime(),
            end: end.getTime(),
            timed: true,
          }
        }
      })
    },
    setToday() {
      this.value = ''
    },
    async showEvent({ nativeEvent, event }) {
      if (this.canUpdate) {
        const open = async () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          const item = await this.getProgramacaoUnidade(event.item.public_id)
          this.SET_PROGRAMACAO_UNIDADE(item)
          await this.editItem(item)
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          )
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          await requestAnimationFrame(
            async () => await requestAnimationFrame(async () => await open())
          )
        } else {
          await open()
        }

        nativeEvent.stopPropagation()
      }
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16)
      const r = (rgb >> 16) & 0xff
      const g = (rgb >> 8) & 0xff
      const b = (rgb >> 0) & 0xff

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color
    },
  },
}
</script>
